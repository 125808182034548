import SeriesInfoForm from '../../components/seriesInfo'

const NewSeriesPage = (props) => {
  return (
    <div className="container">
      <h1 className="text-uppercase fw-bolder App-Page-Title">Create New Series</h1>
      <SeriesInfoForm />
    </div>
  );
}

export default NewSeriesPage;