import { PluggedFireStore } from '../lib/firebase'
import { doc, setDoc, updateDoc, getDoc, serverTimestamp, arrayUnion, deleteDoc, deleteField, arrayRemove } from 'firebase/firestore'
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage'

import { v4 as uuidv4 } from 'uuid';
import { deleteAllConnections } from '../utils/helpers/dataModifications';

const setUpdateFields = async (uid, PluggedCollection, fields) => {
  const docRef = doc(PluggedFireStore, PluggedCollection, uid);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    await updateDoc(docRef, { ...fields })
  } else {
    console.log("Document not found")
    return { text: 'failed', status: 500 }
  }
  console.log("document updated");
  return { text: 'complete', status: 200 }
}

const setUpdateNestedFieldName = async (data) => {
  const { collectionId, uid, fieldName, updatedFields} = data;
  
  try {
    const CatalogRef = doc(PluggedFireStore, collectionId, uid);
    const docSnap = await getDoc(CatalogRef);

    if (docSnap.exists()) {
      await updateDoc(CatalogRef, {
        [fieldName]: {
          ...updatedFields,
          dateAdded: serverTimestamp()
        }
      })
    }

    return { text: 'complete', code: 200 }
  } catch (error) {
    console.error('setNewCampaign error:', error);
    return { text: 'failed', code: 500 }
  }
}

const setNewSeries = async (data) => {
  const uuid = 'SE-' + uuidv4();

  try {
    const docRef = doc(PluggedFireStore, 'catalog', uuid);
    await setDoc(docRef, {
      ...data,
      episodes: [],
      entryId: uuid,
      featured: false,
      sponsor: {},
      dateAdded: serverTimestamp(),
      releaseDate: new Date().toString(),
    });
    return { text: 'complete', code: 200 }
  } catch (error) {
    console.error('setNewSeries error:', error);
    return { text: 'failed', code: 500 }
  }
}

const setNewEpisode = async (data) => {
  const uuid = 'EP-' + uuidv4();
  const { catalogId } = data;
  try {
    const docRef = doc(PluggedFireStore, 'episodes', uuid);

    const CatalogRef = doc(PluggedFireStore, 'catalog', catalogId);
    const docSnap = await getDoc(CatalogRef);

    if (docSnap.exists()) {
      await updateDoc(CatalogRef, {
        episodes: arrayUnion(uuid)
      })
    }

    await setDoc(docRef, {
      ...data,
      entryId: uuid,
      dateAdded: serverTimestamp(),
      releaseDate: new Date().toString(),
    });

    return { text: 'complete', code: 200 }
  } catch (error) {
    console.error('setNewEpisode error:', error);
    return { text: 'failed', code: 500 }
  }
}

const setNewCampaign = async (partnerId, data, campaignId) => {
  const uuid = uuidv4();
  const { name, videoURL, active } = data;

  try {
    const CatalogRef = doc(PluggedFireStore, 'adPartners', partnerId);
    const docSnap = await getDoc(CatalogRef);

    if (docSnap.exists()) {
      const payload = {
        active: active,
        eligible: [],
        entryId: campaignId ? campaignId : uuid,
        name: name,
        videoURL: videoURL
      }
      const fieldName = `campaigns.${campaignId ? campaignId : uuid}`;

      await updateDoc(CatalogRef, {
        [fieldName]: {
          ...payload,
          dateAdded: serverTimestamp()
        }
      })
    }

    return { text: 'complete', code: 200 }
  } catch (error) {
    console.error('setNewCampaign error:', error);
    return { text: 'failed', code: 500 }
  }
}

const setNewCampaignConnection = async (data) => {
  // validated: passed 02/22/24
  const { partnerId, campaignId, connections } = data;

  connections.forEach(async (bridgeConnection) => {
    // update campaign connections in partners
    try {
      const PartnerRef = doc(PluggedFireStore, 'adPartners', partnerId);
      const PartnerDoc = await getDoc(PartnerRef);


      if (PartnerDoc.exists()) {
        //const partnerData = PartnerDoc.data();
        const fieldName = `campaigns.${campaignId}.eligible`;
        // const reforData = ConvertCampaignObject(partnerData.campaigns);

        if (!bridgeConnection.videos.length || (bridgeConnection.videos.length === 1 && Array.isArray(bridgeConnection.videos[0]))) {
          const SeriesRef = doc(PluggedFireStore, 'catalog', bridgeConnection.series.id);
          // add to eligible on partner
          await updateDoc(PartnerRef, {
            [fieldName]: arrayUnion(bridgeConnection.series.id)
          });
          // this is a sponsor
          await updateDoc(SeriesRef, {
            sponsor: {
              partner: partnerId,
              campaign: campaignId
            }
          });
        } else {
          // add to eligible on partner
          await updateDoc(PartnerRef, {
            [fieldName]: arrayUnion(...bridgeConnection.videos)
          });
          // add to video bucket for each
          bridgeConnection.videos.forEach(async videoId => {
            const episodeFieldName = `advertisement.buckets.${partnerId}`;
            if (videoId !== "" && !Array.isArray(videoId)) {
              const VideoRef = doc(PluggedFireStore, 'episodes', videoId);
              await updateDoc(VideoRef, {
                [episodeFieldName]: arrayUnion(campaignId)
              });
            }
          });
        }
      }
      return { text: 'success', code: 200 }
    } catch (error) {
      console.error('setNewCampaignConnection error:', error);
      return { text: 'failed', code: 500 }
    }
  });
}

const setNewCollectionItem = async (collectionId, data) => {
  const uuid = uuidv4();

  try {
    const docRef = doc(PluggedFireStore, collectionId, uuid);
    await setDoc(docRef, {
      ...data,
      campaigns: {},
      entryId: uuid,
      dateAdded: serverTimestamp(),
    });
    return { text: 'complete', code: 200 }
  } catch (error) {
    console.error('setNewPartner error:', error);
    return { text: 'failed', code: 500 }
  }
}

const deleteSeries = async (data) => {
  // validated: passed 02/25/24
  const { seriesId } = data;
  try {
    const docRef = doc(PluggedFireStore, 'catalog', seriesId);
    const docSnap = await getDoc(docRef);
  
    if (docSnap.exists()) { 
      const seriesInfo = docSnap.data();
      const episodeList =  seriesInfo.episodes;
      episodeList.forEach( async (episode) => {
        await deleteEpisode({
          seriesId: seriesId,
          episodeId: episode,
          skipSeries: true
        })
      });

      if(!!seriesInfo.sponsor.partner) {
         // if series has a sponsor, updated that partner account
        const campRef = doc(PluggedFireStore, 'adPartners', seriesInfo.sponsor.partner);
        const campSnap = await getDoc(campRef);
        if (campSnap.exists()) { 
          await updateDoc(campRef, {
            [`campaigns.${seriesInfo.sponsor.campaign}.eligible`]: arrayRemove(seriesId)
          })
        }
      }
    } else {
      console.log("No such document!");
    }
  } catch (error) {
    console.log('err', error)
  }
  // finally delete the series from the catalog
  console.log('series to delete', seriesId);
  await deleteDoc(doc(PluggedFireStore, 'catalog', seriesId));
}

const deleteEpisode = async (data) => {
  // validated: passed 02/23/24
  const { seriesId, episodeId, skipSeries } = data;
  // this is meant to be called iteratively
  try {
    const episodeRef = doc(PluggedFireStore, 'episodes', episodeId);
    const episodeSnap = await getDoc(episodeRef);
  
    if (episodeSnap.exists()) { 
      // get episode partners
      const episodeInfo = episodeSnap.data();
      await deleteAllConnections({
        partners: episodeInfo.advertisement.buckets, 
        id: episodeId
      })
       // finally delete this episode from db
      await deleteDoc(doc(PluggedFireStore, 'episodes', episodeId));
    }

    const docRef = doc(PluggedFireStore, 'catalog', seriesId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists() && !skipSeries) { 
      // remove episode from series
      await updateDoc(docRef, {
        'episodes': arrayRemove(episodeId)
      })
    }

  } catch (err) {
    console.log('deleteEpisode: err', err)
  }
}

const deleteCampaign = async (data) => {
  // validated: passed 02/08/24
  const { campaignConnections, campaignId, partnerId } = data;
  campaignConnections.forEach(async (itemId) => {
    const key = itemId.slice(0, 2);
    const collectionId = key === "SE" ? 'catalog' : 'episodes';

    try {
      const docRef = doc(PluggedFireStore, collectionId, itemId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const docData = docSnap.data();

        if (collectionId === "episodes") {
          // remove this campaign from list
          await updateDoc(docRef, {
            [`advertisement.buckets.${partnerId}`]: arrayRemove(campaignId)
          })
        } else {
          if(docData.sponsor.partner === partnerId && docData.sponsor.campaign === campaignId) {
            await updateDoc(docRef, {
              sponsor: {}
            })
          }
        }
      }
    } catch (error) {
      console.error(`${collectionId} single item: error`, error);
    }
  }); // end for each 

  // remove campaign from partner
  try {
    // tested and pass: 02/08/24
    const docRef = doc(PluggedFireStore, 'adPartners', partnerId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      await updateDoc(docRef, {
        [`campaigns.${campaignId}`]: deleteField()
      });
    } else {
      console.log("No such document!");
    }

  } catch (error) {
    console.error('deleteCampaign error:', error);
  }
}

const deleteConnection = async (data) => {
  // validated: passed 02/22/24
  const { fieldName, documentId, subType, subId, subDocumentId } = data;

  try {
    const PartnerRef = doc(PluggedFireStore, 'adPartners', documentId);
    const subRef = doc(PluggedFireStore, subType, subDocumentId);
    const docSnap = await getDoc(PartnerRef);
    const subSnap = await getDoc(subRef);

    if (docSnap.exists()) {
      // update partner
      await updateDoc(PartnerRef, {
        [fieldName]: arrayRemove(subDocumentId)
      });
    }

    if (subSnap.exists()) {
      // update anywhere that is changed from this 
      const payload = subSnap.data();

      if (subType === "episodes") {
        await updateDoc(subRef, {
          [`advertisement.buckets.${documentId}`]: arrayRemove(subId)
        });
      }

      if (subType === "catalog" && payload.sponsor.partner === documentId && payload.sponsor.campaign === subId) {
        await updateDoc(subRef, {
          sponsor: {}
        });
      }
    }

    return { text: 'complete', code: 200 }
  } catch (error) {
    console.error('deleteConnection error:', error);
    return { text: 'failed', code: 500 }
  }
}

const uploadAsset = async (fileUri, folder) => {
  const uuid = uuidv4();

  try {
    const response = await fetch(fileUri);
    const blob = await response.blob();
    const storage = getStorage();
    const subFolder = !!folder ? folder : '';
    const storageRef = ref(storage, `${subFolder}/${uuid}`);
    const metadata = {
      cacheControl: 'public,max-age=864000'
    };
    return uploadBytes(storageRef, blob, metadata).then(async (snapshot) => {
      const downloadURL = await getDownloadURL(snapshot.ref);
      return downloadURL;
    });
  } catch (err) {
    console.error('setNewSeries error:', err);
  }
}
const removeAsset = async (fileName, collection) => {
  const storage = getStorage();
  const desertRef = ref(storage, `${collection}/thisbit/${fileName}`);
  // Delete the file
  deleteObject(desertRef).then(() => {
    // File deleted successfully
  }).catch((error) => {
    // Uh-oh, an error occurred!
  });
}

export {
  setUpdateFields,
  setNewSeries,
  setNewEpisode,
  setNewCollectionItem,
  setNewCampaign,
  setNewCampaignConnection,
  setUpdateNestedFieldName,
  deleteConnection,
  deleteCampaign,
  deleteSeries,
  deleteEpisode,
  uploadAsset,
  removeAsset
}