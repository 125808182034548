import { useState } from 'react';

const ToggleSwitch = (props) => {
  const [isEnabled, setIsEnabled] = useState(props.enabled);

  const handleFeatureToggle = () => {
    setIsEnabled(!isEnabled);
    props.onChange();
  }

  return (
    <div className={`form-check form-switch ${props.classNames}`}>
      <input 
        className="form-check-input App-Toggle-Switch mt-0" 
        type="checkbox" 
        role="switch" 
        checked={isEnabled} 
        onChange={() => handleFeatureToggle()}
      />
      {props.label && <label className="form-check-label ms-2">{props.label}</label>}
    </div>
  )
}

export default ToggleSwitch;