import { useState, useEffect } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { getSingleItem } from '../../data/getter'
import EpisodeInfoForm from '../../components/episodeInfo'
import { deleteEpisode } from '../../data/setters'
import ConfirmationModal from '../../components/modals/confirmation'

const EpisodeDetailPage = () => {
  let { seriesId, episodeId } = useParams();
  const [episodeInfo, setEpisodeInfo] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const navigate = useNavigate();

  const fetchSeries = async () => {
   const episodeData = await getSingleItem('episodes',episodeId);
   setEpisodeInfo(episodeData[0]);
  }
  const handleDelete = async () => {
    await deleteEpisode({
      seriesId: episodeInfo.catalogId, 
      episodeId: episodeId
    });
    setTimeout(() => {
      navigate(`/series/${seriesId}`);
    }, 200);
  }
  useEffect(() => {
    fetchSeries();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[episodeId]);

  if(!episodeInfo){
    return;
  }

  return (
    <div className="container">
    {showDeleteModal &&
      <ConfirmationModal
        modalTitle="Delete Episode"
        confirmationAction={handleDelete}
        cancelAction={() => setShowDeleteModal(false)}
        title="Are you sure you want to delete this episode?"
        subtitle="Episode deletion will also update partner campaigns respectively."
        cancelButton="No, my bad"
        confirmationButton="Yes, I want to delete it"
        showDisclaimer={true}
      />
    }
      <Link to={`/series/${seriesId}`} className="App-Inline-Link App-Inline-Link__plain mb-2 d-inline-block"><i className="bi bi-arrow-left-square"></i> Go back to series</Link>
      <h1 className="text-uppercase fw-bolder App-Page-Title">Manage Episode</h1>
      <EpisodeInfoForm defaultData={episodeInfo} />
      <section className="bg-warning-subtle p-4 mt-5">
        <h4>Danger Zone:</h4>
        <button className="btn btn-danger my-2" onClick={() => setShowDeleteModal(true)}>Delete Episode</button>
        <p className="fs-small">This action can not be undone. Deleting an episode will also remove from partner campaigns.</p>
      </section>
    </div>
  );
}

export default EpisodeDetailPage;