import * as React from 'react'
import ReactDOM from 'react-dom/client'
import InteriorLayout from './layouts/interior'
import NoAuthLayout from './layouts/noAuth'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'

// Styles
import 'bootstrap/dist/css/bootstrap.css'
import './assets/styles/index.css';
import './assets/styles/App.css';

// pages
import DashBoardPage from './pages/dashBoard'
import ManageFeaturedSeriesPage from './pages/managePages/featuredSeries'
// series
import NewSeriesPage from './pages/createPages/series'
import ManageSeriesPage from './pages/managePages/series'
import SeriesDetailPage from './pages/detailPages/series'
// episodes
import NewEpisodePage from './pages/createPages/episode'
import EpisodeDetailPage from './pages/detailPages/episode'
// partners
import NewPartnerPage from './pages/createPages/partner'
import ManagePartnerPage from './pages/managePages/partners'
import PartnerDetailPage from './pages/detailPages/partner'
import CampaignDetailPage from './pages/detailPages/campaign'
// static
import LoginPage from './pages/login'
// 404s
import FourOhFour from './components/errors/fourOhFour'

// Auth
import { AuthProvider } from './lib/context/Auth'
import ScrollToTop from './utils/scrollToTop'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route 
            path="*" 
            element={<InteriorLayout component={<FourOhFour />} />} 
          />
          <Route path="/" element={<Navigate to="/dashboard" />} />
          <Route 
            path="/dashboard" 
            element={<InteriorLayout component={<DashBoardPage />} />} 
          />
          <Route 
            path="/login" 
            element={<NoAuthLayout component={<LoginPage />} />} 
          />
          <Route 
            path="/currated/featured" 
            element={<InteriorLayout component={<ManageFeaturedSeriesPage />}/>} 
          />
          <Route 
            path="/series" 
            element={<InteriorLayout component={<ManageSeriesPage />}/>} 
          />
          <Route 
            path="/series/:seriesId" 
            element={<InteriorLayout component={<SeriesDetailPage />}/>} 
          />
          <Route 
            path="/series/:seriesId/:episodeId" 
            element={<InteriorLayout component={<EpisodeDetailPage />}/>} 
          />
          <Route 
            path="/series/:seriesId/new" 
            element={<InteriorLayout component={<NewEpisodePage />}/>} 
          />
          <Route 
            path="/partners" 
            element={<InteriorLayout component={<ManagePartnerPage />}/>} 
          />
          <Route 
            path="/partners/:partnerId" 
            element={<InteriorLayout component={<PartnerDetailPage />}/>} 
          />
          <Route 
            path="/partners/:partnerId/:campaignId" 
            element={<InteriorLayout component={<CampaignDetailPage />}/>} 
          />
          <Route 
            path="/new/series" 
            element={<InteriorLayout component={<NewSeriesPage />}/>} 
          />
          <Route 
            path="/new/partner" 
            element={<InteriorLayout component={<NewPartnerPage />}/>} 
          />
        </Routes>
      </Router>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
