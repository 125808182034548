import { useState, useEffect } from 'react'
import { setNewCollectionItem, setUpdateFields } from '../data/setters'
import Loading from './loading'
import Toast from './modals/toast'
import ToggleSwitch from './form/toggle'

const PartnerInfoForm = (props) => {
  let defaultData = {
    active: null,
    company: "",
    contactName: [],
    contactEmail: "",
    entryId: ""
  };
  const cacheData = props?.defaultData ? props?.defaultData : defaultData;
  // console.log('kk cache',cacheData);
  const [clientName, setClientName] = useState(cacheData.company);
  const [contactName, setContactName] = useState(cacheData.contactName);
  const [contactEmail, setContactEmail] = useState(cacheData.contactEmail);
  const [active, setActive] = useState(cacheData.active);
  const [partnerId, setPartnerId] = useState(cacheData.entryId);
  const [showToast, setShowToast] = useState(null);

  const handleChange = () => {
    setShowToast("show");
    setTimeout(() => {
      setShowToast(null);
    },1500);
  }

  const handleNewPartner = async () => {
    const payload = {
      active: active,
      company: clientName,
      contactEmail: contactEmail,
      contactName: contactName
    }

    if(props.defaultData) {
      await setUpdateFields(partnerId, 'adPartners', payload);
    } else {
      await setNewCollectionItem('adPartners',payload);
      setClientName("");
      setContactName("");
      setContactEmail("");
      setActive(false);
    }
    handleChange();
  }

  useEffect(() => {
    if(!!props.defaultData) {
      setClientName(props.defaultData.company);
      setContactName(props.defaultData.contactName);
      setContactEmail(props.defaultData.contactEmail);
      setPartnerId(props.defaultData.entryId);
      setActive(props.defaultData.active);
    }
  },[props.defaultData]);

  if(props.defaultData && !clientName) {
    return <Loading />
  }

  return (
    <div>
      <Toast title={`Partner has been sucessfully ${props.defaultData ? 'updated' : 'added'}.`} trigger={showToast} />
      <form id="new-partner-form">
        <ToggleSwitch onChange={() => setActive(!active)} enabled={active} label="Active" classNames="mb-4"/>
        <div>
          <label htmlFor="partner-name" className="App-Input-Label mb-2 d-block">partner Name</label>
          <input
          id="partner-name"
          type="text"
          name="partner-name"
          value={clientName}
          placeholder="Nike Corporation"
          className="form-control App-Input mb-4"
          onInput={(e) => setClientName(e.target.value)} />
        </div>
        <hr className="line-break" />
        <div>
          <label htmlFor="contact-name" className="App-Input-Label mb-2 d-block">Contact Name</label>
          <input
          id="contact-name"
          type="text"
          name="series-name"
          value={contactName}
          placeholder="John Smith"
          className="form-control App-Input mb-4"
          onInput={(e) => setContactName(e.target.value)} />
        </div>
        <div>
          <label htmlFor="contact-email" className="App-Input-Label mb-2 d-block">Contact Email</label>
          <input
          id="contact-email"
          type="text"
          name="series-name"
          value={contactEmail}
          placeholder="johnsmith@nike.com"
          className="form-control App-Input mb-4"
          onInput={(e) => setContactEmail(e.target.value)} />
        </div>
        <button type="button" className="btn btn-primary" onClick={handleNewPartner}>{props.defaultData ? 'Update' : 'Create'} Partner</button>
      </form>
    </div>
  );
}

export default PartnerInfoForm;