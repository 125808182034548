import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { PluggedAuth } from '../lib/firebase'
import { signOut } from 'firebase/auth'

import logo from '../plugged-logo--dark.png'
import FiveHundred from '../components/errors/fiveHundred'
import { AuthContext } from '../lib/context/Auth'
import CollapsedMenu from '../components/collapsedMenu'

const InteriorLayout = (props) => {
  const {currentUser} = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const [menuExpanded, setMenuExpanded] = useState(true);

  const handleLogOut = () => {
    signOut(PluggedAuth).then(() => {
      // Sign-out successful
      console.log("user logged out");
    }).catch((error) => {
      // An error happened.
    });
  }

  useEffect(() => {
    if(!currentUser){
      navigate(`/login`);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[currentUser]);

  return (
    <main className={`row ${!menuExpanded ? 'App-Menu-Collapsed' : ''}`}>
      <section className="menu col-4 App-Main-Nav">
        <div className="App-Collapsed-Menu">
          <CollapsedMenu />
        </div>
        <section className="px-4 pt-4 position-relative">
          <img src={logo} className="App-logo" alt="logo" />
          <span className="px-0 mx-0 mb-4 d-block">Data Manager Tool</span>
          <button className="App-Nav-Togggle" onClick={() => setMenuExpanded(!menuExpanded)}><i className="bi bi-arrows-expand-vertical"></i></button>
        </section>
        <section className="App-Nav-Section-Wrapper">
          <h5 className="App-Nav-Section px-4 my-0">Dashboard</h5>
          <nav className="nav flex-column">
            <Link to="/dashboard" 
            className={`nav-link text-capitalize App-Nav-link ps-4 py-3 d-flex w-100 justify-content-between align-items-center ${pathname.includes('/dashboard') ? 'App-Navigation-Active' : ''}`} 
            href="#">
              <span>Home</span>
              <span className="App-Nav-Icon-Container">
                <i className="bi bi-house"></i>
              </span>
            </Link>
          </nav>
        </section>
        <section className="App-Nav-Section-Wrapper">
          <h5 className="App-Nav-Section px-4 my-0">Catalog</h5>
          <nav className="nav flex-column">
            <Link to="/series/" 
            className={`nav-link text-capitalize App-Nav-link ps-4 py-3 d-flex w-100 justify-content-between align-items-center ${pathname.includes('series/') ? 'App-Navigation-Active' : ''}`} 
            href="#">
              <span>Manage existing series</span>
              <span className="App-Nav-Icon-Container">
                <i className="bi bi-tv"></i>
              </span>
            </Link>
            <Link to="/new/series" 
            className={`nav-link text-capitalize App-Nav-link ps-4 py-3 d-flex w-100 justify-content-between align-items-center ${pathname === '/new/series' ? 'App-Navigation-Active' : ''}`} 
            href="#">
              <span>Create new series</span>
              <span className="App-Nav-Icon-Container">
              <i className="bi bi-camera-video"></i>
              </span>
            </Link>
          </nav>
        </section>
        <section className="App-Nav-Section-Wrapper">
          <h5 className="App-Nav-Section px-4 my-0">Partners</h5>
          <nav className="nav flex-column">
            <Link 
            to="/partners/" 
            className={`nav-link text-capitalize App-Nav-link ps-4 py-3 d-flex w-100 justify-content-between align-items-center ${pathname.includes('partners/') ? 'App-Navigation-Active' : ''}`} 
            href="#">
              <span>manage existing partners</span>
              <span className="App-Nav-Icon-Container">
                <i className="bi bi-badge-ad"></i>
              </span>
              </Link>
            <Link 
            to="/new/partner" 
            className={`nav-link text-capitalize App-Nav-link ps-4 py-3 d-flex w-100 justify-content-between align-items-center ${pathname === '/new/partner' ? 'App-Navigation-Active' : ''}`}
            aria-current="page" 
            href="#">
              <span>Create new partner</span>
              <span className="App-Nav-Icon-Container">
              <i className="bi bi-person-plus"></i>
              </span>
            </Link>
          </nav>
        </section>
        <section className="App-Nav-Section-Wrapper">
          <h5 className="App-Nav-Section px-4 my-0">Currated</h5>
          <nav className="nav flex-column">
            <Link 
            to="/currated/featured" 
            className={`nav-link text-capitalize App-Nav-link ps-4 py-3 d-flex w-100 justify-content-between align-items-center ${pathname === '/currated/featured' ? 'App-Navigation-Active' : ''}`} 
            href="#">
              <span>Manage featured series</span>
              <span className="App-Nav-Icon-Container">
                <i className="bi bi-collection"></i>
              </span>
            </Link>
          </nav>
        </section>
      </section>
      <section className="col-4 App-Fixed-Correction"></section>
      <section className="col-8 ms-0 ps-0 pe-0 App-Main-Content">
        <header className="w-100 py-2 px-4 bg-black text-white mb-4">
          <div className="d-flex justify-content-end align-items-center">
            <i className="bi bi-person-circle fs-3"></i>
            <span className="px-2 text-small">{currentUser?.email}</span>
            <button className="btn btn-dark ms-2 fs-small" onClick={handleLogOut}>logout</button>
          </div>
        </header>
        <div className="container pb-4">
          <ErrorBoundary fallback={<FiveHundred />}>
            {props.component}
          </ErrorBoundary>
        </div>
      </section>
    </main>
  )
}
export default InteriorLayout;