import { PluggedFireStore } from '../lib/firebase'
import { collection, query, where, getDocs, getDoc, doc } from 'firebase/firestore'
import { ConvertCampaignObject } from '../utils/capaignObject'

const getByFieldQuery = async (data) => {
  const {collectionId, fieldName, fieldValue, condition} = data;
  try {
    let data = [];
    const q = query(collection(PluggedFireStore, collectionId), where(fieldName, condition || "==", fieldValue));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const resData = doc.data();
      data.push(resData);
    });
    return data;

  } catch (error) {
    console.error('getByFieldQuery error:',error);
  }
}

const getAllInArray = async (collectionId, episodeIds) => {
  let data = [];
  const myPromise = new Promise((resolve, reject) => {
    episodeIds.map( async (episodeId, index) => {
      const docRef = doc(PluggedFireStore, collectionId, episodeId);
      const docSnap = await getDoc(docRef);
     
      if (docSnap.exists()) {  
        const episodeInfo = [docSnap.data()];
        data.push(...episodeInfo);
      }

      if(index + 1 === episodeIds.length) {
        resolve();
      }
    });
    
  });
    
  return myPromise.then(() => {
    return data;
  }).catch((err) => {
    console.error(`getAllInArray error: ${collectionId}`,err);
  });
}

const getCampaignDetail = async (partnerId, campaignId) => {
  try {
    const fetchCampaign = await getSingleItem('adPartners',partnerId);
    const restructuredCampaigns = ConvertCampaignObject(fetchCampaign[0].campaigns);
    const campaignInfoId = restructuredCampaigns.filter((item) => item.entryId === campaignId);
    console.log('da', restructuredCampaigns, campaignInfoId);

    return campaignInfoId;
  } catch (error) {
    console.error(`getCampaignDetail: error`,error);
  }
}

const getBridgeItems = async (params) => {
  let bridgeData = [];
  let videoData = [];
  const {collectionId, partnerId, bridgeId, campaignId } = params;
  console.log('ids', collectionId, partnerId, bridgeId, campaignId);
  try {
    if(!bridgeId) {
      return
    }
    const docRef = doc(PluggedFireStore, collectionId, bridgeId);
    const docSnap = await getDoc(docRef);
  
    if (docSnap.exists()) {  
      const data = docSnap.data();
      if(data) {
        bridgeData.push(data);
      }
      if(collectionId === "episodes") {
        const episodeRef = doc(PluggedFireStore, "catalog", data.catalogId);
        const episdoeSnap = await getDoc(episodeRef);

        if (episdoeSnap.exists()) {  
          const dataInfo = episdoeSnap.data();
          if(dataInfo) {
            videoData.push(dataInfo);
          }
        }
      }
      
    } else {
      console.log("No such document!");
    }
    return {
      item: bridgeData,
      parentSeries: videoData
    };
  } catch (error) {
    console.error(`${collectionId} single item: error`,error);
  }
}

const getSingleItem = async (collectionId, uid) => {
  let data = [];

  try {
    const docRef = doc(PluggedFireStore, collectionId, uid);
    const docSnap = await getDoc(docRef);
  
    if (docSnap.exists()) {  
      data.push(docSnap.data());
    } else {
      console.log("No such document!");
    }
    return data;
  } catch (error) {
    console.error(`${collectionId} single item: error`,error);
  }
}

const getCollection = async (collectionId) => {
  let data = [];
  try {
    const querySnapshot = await getDocs(collection(PluggedFireStore, collectionId));
    querySnapshot.forEach((doc) => {
      const resData = doc.data();
      data.push(resData);
    });
    return data;
  } catch (error) {
    console.error('getCatalogItems error:',error);
  }
}

export { 
  getSingleItem,
  getCollection,
  getAllInArray,
  getByFieldQuery,
  getCampaignDetail,
  getBridgeItems
};