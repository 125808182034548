import React from 'react'
import FiveHundredAnimation from '../../assets/fiveHundred.gif';

const FiveHundred = () => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <img src={FiveHundredAnimation} alt="unknown error" className="fourOhfour-image" />
      <h4 className="text-uppercase fw-bold">Well now you've done it!</h4>
      <span className="d-block text-secondary">There was an unknown error. Check your link and try again.</span>
      <span className="d-block fs-6 text-body-tertiary">If this error persists, please contact support.</span>
    </div>
  )
}

export default FiveHundred;