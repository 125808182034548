import {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import { getCollection } from '../../data/getter'
import NoResultsBanner from '../../components/errors/emptyData'
import Loading from '../../components/loading'
import FallBackCover from '../../assets/fallbackCover.png'

const ManageSeriesPage = () => {
  const [catalogData, setCatalogData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchCatalog = async () => {
      const catalogItems = await getCollection("catalog");
      setCatalogData(catalogItems);
      setIsLoading(false);
    }
    fetchCatalog();
  },[]);

  if(isLoading) {
    return <Loading />
  }
  return (
    <div>
      <div className="position-relative w-100">
        <h1 className="text-uppercase fw-bolder App-Page-Title">Manage Series</h1>
        <Link to={`/new/series`} className="btn btn-outline-dark position-absolute top-0 end-0 me-2">Create new series</Link>
      </div>
      <section className="container">
        <div className="d-flex border-bottom my-3 pb-2 row App-Flex-Table">
          <div className="col-2">
            <span className="fw-semibold fs-5 secondary-font">Thumbnail</span>
          </div>
          <div className="col-6">
            <span className="fw-semibold fs-5 secondary-font">Title</span>
          </div>
          <div className="col-2">
            <span className="fw-semibold fs-5 secondary-font">Episodes</span>
          </div>
          <div className="col-2">&nbsp;</div>
        </div>
      </section>
      <section className="container">
        {catalogData && !!catalogData.length ? catalogData.map((series) => {
          return (
          <div className="row d-flex border-bottom mb-2 pb-2 App-Flex-Table App-Flex-Table__row" key={series.cKey}>
            <div className="col-2">
              <img className="App-Thumbnail-Poster" src={series.cover || FallBackCover} alt={`${series.title} cover`} />
            </div>
            <div className="col-6 align-self-center">
              <h4 className="text-capitalize mb-0">{series.title}</h4>
              <span className="fs-6 text-secondary">{series.subtitle}</span>
            </div>
            <div className="col-2 align-self-center">
              <span>{series.episodes.length}</span>
            </div>
            <div className="col-2 align-self-center text-center"><Link to={`/series/${series.cKey}`} className="btn btn-dark fs-small">Manage</Link></div>
          </div>
        )
        }) : <NoResultsBanner name="series" />}
      </section>
    </div>
  );
}

export default ManageSeriesPage;