import { useEffect, useState } from 'react'

const Toast = (props) => {
  const [displayToast, setDisplayToast] = useState(false);

  useEffect(() => {
    if(props.trigger === "show") {
      setDisplayToast(true);
      setTimeout(() => {
          setDisplayToast(false);
      },2500);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.trigger]);

  return (
    <div className={`toast align-items-center text-bg-primary border-0 top-0 end-0 me-4 mt-4 position-fixed ${!!displayToast ? 'show' : ''}`} role="alert" aria-live="polite" aria-atomic="true">
      <div className="d-flex">
        <div className="toast-body">
          {props.title}
        </div>
        <button type="button" className="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close" onClick={() => setDisplayToast(false)}></button>
      </div>
    </div>
  );
}

export default Toast;