import React from 'react'
import EmptyDataset from '../../assets/emptyDataset.gif';

const NoResultsBanner = (props) => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <img src={EmptyDataset} alt="404" className="fourOhfour-image" />
      <span>There were no {props.name} found.</span>
    </div>
  )
}

export default NoResultsBanner;