import { PluggedFireStore } from '../../lib/firebase'
import { doc, updateDoc, getDoc, arrayRemove } from 'firebase/firestore'


const deleteFromCampaign = async (data) => {
  // validated: passed 02/25/24
  const { partnerId, campaignId, idToRemove } = data;

  try {
    const PartnerRef = doc(PluggedFireStore, 'adPartners', partnerId);
    const docSnap = await getDoc(PartnerRef);

    if (docSnap.exists()) {
      // update partner
      console.log('id delete', idToRemove);

      await updateDoc(PartnerRef, {
        [`campaigns.${campaignId}.eligible`]: arrayRemove(idToRemove)
      });
    }

    return { text: 'complete', code: 200 }
  } catch (error) {
    console.error('deleteConnection error:', error);
    return { text: 'failed', code: 500 }
  }
}

const deleteAllConnections = async (data) => {
  // validated: passed 02/25/24
  const {partners, id} = data;
  Object.keys(partners).forEach( async (partnerId) => {
    // loop through partners for this episode
    const thisItem = partners[partnerId];
    thisItem.forEach(async (campaignId) => {
      console.log('ep i', campaignId);
    // loop through all partner campaigns and remove episode from this episode
      await deleteFromCampaign({
        partnerId: partnerId, 
        campaignId: campaignId, 
        idToRemove: id
      });
    })
  });
}
export {deleteFromCampaign, deleteAllConnections}