import { useEffect, useState } from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { getAllInArray, getByFieldQuery } from '../../data/getter'
import SeriesInfoForm from '../../components/seriesInfo'
import FourOhFour from '../../components/errors/fourOhFour'
import NoResultsBanner from '../../components/errors/emptyData'
import ConfirmationModal from '../../components/modals/confirmation'
import Loading from '../../components/loading'
import FallbackPoster from '../../assets/fallbackPoster.png'
import { deleteSeries } from '../../data/setters'

const SeriesDetailPage = () => {
  let { seriesId } = useParams();
  const [seriesDefaultData, setSeriesDefaultData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [episodes, setEpisodes] = useState([]);
  const navigate = useNavigate();

  const getData = async () => {
    const defaultData = await getByFieldQuery({
      collectionId: "catalog",
      fieldName: "cKey",
      fieldValue: seriesId
    });
    if (defaultData && defaultData[0]?.episodes.length) {
      const episdoeData = await getAllInArray("episodes",defaultData[0].episodes);
      setEpisodes(episdoeData); 
    }
    setSeriesDefaultData(defaultData);
    setIsLoading(false);
  }
  
  const handleSeriesDeletion = async () => {
    await deleteSeries({
      seriesId: seriesDefaultData[0].entryId, 
    });
    setTimeout(() => {
      navigate(`/series/`);
    }, 500);
  }
  useEffect(() => { 
    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[seriesId]);

  if(!isLoading && !seriesDefaultData.length) {
    return <FourOhFour />
  }

  if(isLoading) {
    return <Loading />
  }
  return (
    <div>
      {showConfirmationModal &&
      <ConfirmationModal
          modalTitle="Series Deletion"
          confirmationAction={handleSeriesDeletion}
          cancelAction={() => setShowConfirmationModal(false)}
          title="Are you sure you want to delete this series? This action can not be undone."
          subtitle="Series deletion will also delete all episodes and update partner campaigns respectively."
          cancelButton="No, my bad."
          confirmationButton="Yes, I want to delete it."
          showDisclaimer={true}
      /> }
      <div className="container">
      <Link to={`/series/`} className="App-Inline-Link App-Inline-Link__plain mb-2 d-inline-block"><i className="bi bi-arrow-left-square"></i> Go back to series list</Link>
        <h1 className="text-uppercase fw-bolder App-Page-Title">Manage Series: <span className="fw-light text-capitalize d-block fs-5">{seriesDefaultData[0].title}</span></h1>
        <SeriesInfoForm defaultData={seriesDefaultData || []} />
        <hr />
        <div>
          <section className="mt-4">
            <div className="d-flex justify-content-between pb-4">
              <h4>Episodes</h4>
              {seriesDefaultData && <Link to={`/series/${seriesId}/new`} type="button" className="btn btn-outline-dark" name="submit">Add New Episode</Link> }
            </div>
            {seriesDefaultData && seriesDefaultData[0]?.episodes.length ?
              <div className="container">
                <div className="d-flex border-bottom my-3 pb-2 row App-Flex-Table">
                  <div className="col-2 ps-0">
                    <span className="fw-semibold fs-5 secondary-font">Thumbnail</span>
                  </div>
                  <div className="col-8">
                    <span className="fw-semibold fs-5 secondary-font">Title</span>
                  </div>
                  <div className="col-2">&nbsp;</div>
                </div>
                {episodes && episodes.map((episode) => {
                  return(
                    <div className="row border-bottom mb-2 pb-2 mx-0 App-Flex-Table App-Flex-Table__row" key={`${episode.entryId}`}>
                      <div className="col-2 ps-0">
                        <img className="App-Thumbnail-Poster" src={!!episode.poster ? episode.poster : FallbackPoster} alt="ltod cover" />
                      </div>
                      <div className="col-8">
                        <h4 className="text-capitalize mb-0">{episode.title}</h4>
                        <span className="fs-6 text-secondary">{episode.subtitle}</span>
                      </div>
                      <div className="col-2 d-flex"><Link to={`/series/${seriesId}/${episode.entryId}`} type="button" className="btn btn-dark d-block align-self-center w-100">Manage</Link></div>
                    </div>
                  )
                })}
              </div>
            : <NoResultsBanner name="episodes" /> }
          </section>
          <section className="bg-warning-subtle p-4 mt-5">
            <h4>Danger Zone:</h4>
            <button className="btn btn-danger my-2" onClick={() => setShowConfirmationModal(true)}>Delete Series</button>
            <p className="fs-small">This action can not be undone. Deleting a series will also delete the episodes.</p>
          </section>
        </div>
      </div>
    </div>
  );
}

export default SeriesDetailPage;