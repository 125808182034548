import { Link, useLocation } from 'react-router-dom'

const CollapsedMenu = () => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <nav>
      <Link to="/dashboard" 
         className={`App-Nav-Icon-Wrapper App-Nav-Icon-Wrapper__link ${pathname.includes('dashboard/') ? 'App-Navigation-Active' : ''}`}
         title="Home"
         href="#">
        <span className="App-Nav-Icon-Container">
          <i className="bi bi-house"></i>
        </span>
      </Link>
      <Link to="/series/"
        className={`App-Nav-Icon-Wrapper App-Nav-Icon-Wrapper__link ${pathname.includes('series/') ? 'App-Navigation-Active' : ''}`}
        title="Manage Series"
        href="#">
        <span className="App-Nav-Icon-Container">
          <i className="bi bi-tv"></i>
        </span>
      </Link>
      <Link to="/new/series"
        className={`App-Nav-Icon-Wrapper App-Nav-Icon-Wrapper__link ${pathname.includes('new/series') ? 'App-Navigation-Active' : ''}`}
        title="Create New Series"
        href="#">
        <span className="App-Nav-Icon-Container">
          <i className="bi bi-camera-video"></i>
        </span>
      </Link>
      <Link
        to="/partners/"
        className={`App-Nav-Icon-Wrapper App-Nav-Icon-Wrapper__link ${pathname.includes('partners/') ? 'App-Navigation-Active' : ''}`}
        title="Manage Partners"
        href="#">
        <span className="App-Nav-Icon-Container">
          <i className="bi bi-badge-ad"></i>
        </span>
      </Link>
      <Link
        to="/new/partner"
        className={`App-Nav-Icon-Wrapper App-Nav-Icon-Wrapper__link ${pathname.includes('new/partner') ? 'App-Navigation-Active' : ''}`}
        title="Add New Partner"
        aria-current="page"
        href="#">
        <span className="App-Nav-Icon-Container">
          <i className="bi bi-person-plus"></i>
        </span>
      </Link>
      <Link
        to="/currated/featured"
        className={`App-Nav-Icon-Wrapper App-Nav-Icon-Wrapper__link ${pathname.includes('currated/') ? 'App-Navigation-Active' : ''}`}
        title="Featured Series"
        href="#">
        <span className="App-Nav-Icon-Container">
          <i className="bi bi-collection"></i>
        </span>
      </Link>
    </nav>
  )
}
export default CollapsedMenu;