import { useState, useEffect } from 'react'
import { setNewCampaign, setUpdateNestedFieldName } from '../data/setters'
import Loading from './loading'
import Toast from './modals/toast'
import ToggleSwitch from './form/toggle'

const CampaignInfoForm = (props) => {
  let defaultData = {
    active: null,
    name: "",
    videoURL: "",
    entryId: ""
  };
  const cacheData = props?.defaultData ? props?.defaultData : defaultData;
  const [campaignName, setCampaignName] = useState(cacheData.name || "");
  const [campaignVideoURL, setCampaignVideoURL] = useState(cacheData.videoURL || "");
  const [active, setActive] = useState(cacheData.active || false);
  const [partnerId, setPartnerId] = useState(props.partnerId || "");
  const [showToast, setShowToast] = useState(null);

  const handleChange = () => {
    setShowToast("show");
    setTimeout(() => {
      setShowToast(null);
    },1500);
  }

  const handleNewCampaign = async () => {
    const payload = {
      active: active,
      name: campaignName,
      videoURL: campaignVideoURL
    }
        
    if(props.defaultData) {
      // update campaign
      await setUpdateNestedFieldName({
        collectionId: 'adPartners',
        uid: props.partnerId,
        fieldName: `campaigns.${props.campaignId}`,
        updatedFields: {
          active: active,
          name: campaignName,
          videoURL: campaignVideoURL,
          entryId: cacheData.entryId,
          eligible: cacheData.eligible
        }
      });
    } else {
      // create new campaign
      await setNewCampaign(partnerId,payload);
      setCampaignName("");
      setCampaignName("");
      setActive(false);
    }
    
    handleChange();
    if(props.onSubmit) {
      props.onSubmit();
    }
  }

  useEffect(() => {
    if(props.defaultData) {
      setCampaignName(props.defaultData.name);
      setCampaignVideoURL(props.defaultData.videoURL);
      setPartnerId(props.defaultData.entryId);
      setActive(props.defaultData.active);
    }
  },[props.defaultData]);

  if(props.defaultData && !campaignName) {
    return <Loading />
  }

  return (
    <div>
      <Toast title={`Campaign has been sucessfully ${props.defaultData ? 'updated' : 'added'}.`} trigger={showToast} />
      <form id="new-campaign-form">
        <ToggleSwitch onChange={() => setActive(!active)} enabled={active} label="Active" classNames="mb-4"/>
        <div>
          <label htmlFor="partner-name" className="App-Input-Label mb-2 d-block">Campaign Name</label>
          <input
          id="partner-name"
          type="text"
          name="partner-name"
          value={campaignName}
          placeholder="Nike Corporation"
          className="form-control App-Input mb-4"
          onInput={(e) => setCampaignName(e.target.value)} />
        </div>
        <hr className="line-break" />
        <div>
          <label htmlFor="contact-name" className="App-Input-Label mb-2 d-block">Video URL</label>
          <input
          id="contact-name"
          type="text"
          name="series-name"
          value={campaignVideoURL}
          placeholder="https://someurltovideo.com/a-campaign-link"
          className="form-control App-Input mb-4"
          onInput={(e) => setCampaignVideoURL(e.target.value)} />
        </div>
        <button type="button" className="btn btn-primary" onClick={handleNewCampaign}>{props.defaultData ? 'Update' : 'Add'} Campaign</button>
      </form>
    </div>
  );
}

export default CampaignInfoForm;