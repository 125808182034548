const ConfirmationModal = (props) => {
  return (
    <div className="modal-wrapper">
      <div className="modal d-flex align-items-center" tabIndex="-1">
        <div className="modal-dialog w-50">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{props.modalTitle}</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => props.cancelAction()}></button>
            </div>
            <div className="modal-body">
              <p className="fs-5 lh-4 fw-bold">{props.title}</p>
              <p className="fw-light">{props.subtitle}</p>
              {props.showDisclaimer && <span className="text-small">This action can not be undone.</span> }
            </div>
            <div className="modal-footer">
              {props.cancelButton && <button type="button" className="btn btn-outline-dark" onClick={() => props.cancelAction()}>{props.cancelButton}</button>}
              {props.confirmationButton && <button type="button" className="btn btn-danger" onClick={() => props.confirmationAction()}>{props.confirmationButton}</button>}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ConfirmationModal;