import React, { useEffect, useState } from 'react'
import { PluggedAuth } from '../firebase'
import Loading from '../../components/loading'
import { signOut } from 'firebase/auth'
export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);

  const handleLogOut = () => {
    signOut(PluggedAuth).then(() => {
      // Sign-out successful
      console.log("user logged out");
      }).catch((error) => {
        // An error happened.
      });
  }
  
  // Handle user state changes
  async function onAuthStateChanged(profileData)  {
    let uid;
    if(!!profileData) {
      if(profileData?.providerData[0]?.providerId !== 'password') {
        handleLogOut();
        return;
      } 

      if(profileData?.providerData[0]?.providerId === 'password') {
        const emailAdd = profileData?.providerData[0].email;
        const emailDomain = emailAdd.split("@");
        if(emailDomain[1] !== "pluggedmediaco.com") {
          handleLogOut();
          return;
        }
        uid = profileData?.uid;
      } 
      profileData.providerData[0]['uid'] = uid;
    }
    setCurrentUser(profileData);
    if (pending) setPending(false);
  }

  useEffect(() => {
    const subscriber = PluggedAuth.onAuthStateChanged(onAuthStateChanged);
    return subscriber; // unsubscribe on unmount
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  if(pending){
    return <Loading />
  }
  return (
    <AuthContext.Provider
      value={{
        currentUser,
        setCurrentUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
