import { useEffect, useState } from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { getCampaignDetail, getBridgeItems } from '../../data/getter'
import { deleteConnection, deleteCampaign } from '../../data/setters'
import CampaignBridge from '../../components/campaignBridge'
import CampaignInfoForm from '../../components/campaignInfo'
import Loading from '../../components/loading'
import FourOhFour from '../../components/errors/fourOhFour'
import ConfirmationModal from '../../components/modals/confirmation'

import FallbackPoster from '../../assets/fallbackPoster.png'
import FallbackCover from '../../assets/fallbackCover.png'

const CampaignDetailPage = () => {
  let { partnerId, campaignId } = useParams();
  const [campaignInfo, setCampaignInfo] = useState({});
  const [seriesBridge, setSeriesBridge] = useState([]);
  const [episodeBridge, setEpisodeBridge] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [campaignModal, setCampaignModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const navigate = useNavigate();
  let bridges = [];
  let errorKey;
  const fetchCampaign = async () => {
    const campaign = await getCampaignDetail(partnerId, campaignId);
    setIsLoading(false);
    setCampaignInfo(campaign[0]);
  } 

  const fetchBridges = new Promise( async (resolve, reject) => {
    campaignInfo?.eligible?.forEach(async (bridgeId, index) => {
      let collectionId = 'episodes';
      const prefix = bridgeId.slice(0,2);
      if(prefix === "SE") {
        collectionId = 'catalog'
      }
      const bridgeInfo = await getBridgeItems({
        collectionId: collectionId,
        bridgeId: bridgeId,
        partnerId: partnerId,
        campaignId: campaignId
      });
      bridges.push(bridgeInfo);

      if(index + 1 === campaignInfo.eligible.length) {
        setTimeout(() => {
          // need a slight delay for data to propagate
          resolve();
        },[200]) 
      }
    });
  });
    
  const handleConnectionDelete = async (itemId, type) => {
    let newData = campaignInfo.eligible.filter((item) => item !== itemId);
    const payload = {
      fieldName: `campaigns.${campaignId}.eligible`,
      collectionId: 'adPartners',
      documentId: partnerId,
      subType: type,
      subId: campaignId,
      subDocumentId: itemId
    }
    campaignInfo.eligible = newData;
    setCampaignInfo(campaignInfo);
    await deleteConnection(payload);
  }

  const handleCampaignDelete = async () => {
    const payload = {
      campaignConnections: campaignInfo.eligible,
      partnerId: partnerId,
      campaignId: campaignId,
    }
    await deleteCampaign(payload);
    navigate(`/partners/${partnerId}`);
  }
  const handleOnNewBridgeSubmit = () => {
    setCampaignModal(false);
    setTimeout(() => {
      fetchCampaign();
    }, 200);
  }

  useEffect(() => {
    fetchCampaign();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[partnerId, campaignId]);
  
  useEffect(() => {
    fetchBridges.then(() => {
      let bridgeSeries = [];
      let bridgeEpisode = [];
      bridges.forEach((entry) => {
        if(entry?.item[0].entryId.slice(0,2) === "SE") {
          bridgeSeries.push(entry);
        }
        if(entry?.item[0].entryId.slice(0,2) === "EP") {
          bridgeEpisode.push(entry);
        }
      });
      setSeriesBridge(bridgeSeries);
      setEpisodeBridge(bridgeEpisode);
    }).catch(async (err) => {
      console.error(`getBridges error:`,err,errorKey);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[campaignInfo]);

  if(!isLoading && !campaignInfo) {
    return <FourOhFour />
  }

  if(!campaignInfo || isLoading) {
    return <Loading />
  }
  return (
    <div>
     {showConfirmation && <ConfirmationModal
        modalTitle="Delete Campaign"
        confirmationAction={handleCampaignDelete}
        cancelAction={() => setShowConfirmation(false)}
        title="Are you sure you want to delete this campaign? This action can not be undone."
        subtitle="Campaign deletion will remove connections from sponsored series and episodes."
        cancelButton="No, my bad!"
        confirmationButton="I know what I'm doing"
      />
     }
     {campaignModal &&  <div className="modal-wrapper">
        <div className="modal d-flex align-items-center" tabIndex="-1">
          <div className="modal-dialog w-100">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Connection Bridge</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setCampaignModal(false)}></button>
              </div>
              <div className="modal-body">
                <CampaignBridge partnerId={partnerId} campaignId={campaignId} campaignName={campaignInfo.name} onSubmit={handleOnNewBridgeSubmit} />
              </div>
            </div>
          </div>
        </div>
      </div> }
      <div className="container">
        <Link to={`/partners/${partnerId}`} className="App-Inline-Link App-Inline-Link__plain mb-2 d-inline-block"><i className="bi bi-arrow-left-square"></i> Go back to partner</Link>
        <h1 className="text-uppercase fw-bolder App-Page-Title">Manage Campaign:</h1>
        <CampaignInfoForm partnerId={partnerId} campaignId={campaignId} defaultData={campaignInfo} />
        <hr className="my-4" />
        <div className="mt-4 mb-2">
          <div className="position-relative">
            <h3 className="mb-4">Bridges</h3>
            <button className="btn btn-dark position-absolute end-0 top-0" onClick={() => setCampaignModal(true)}>Add Connection</button>
          </div>
          <div className="mb-4">
            <div className="bg-light px-2 rounded"><h5 className="lh-lg">Series</h5></div>
            <section className="container">
              <div className="d-flex border-bottom my-3 pb-2 row App-Flex-Table">
                <div className="col-3">
                  <span className="fw-semibold fs-5 secondary-font">Thumbnail</span>
                </div>
                <div className="col-7">
                  <span className="fw-semibold fs-5 secondary-font">Title</span>
                </div>
                <div className="col-2">&nbsp;</div>
              </div>
            </section>
            <section className="container">
              {!seriesBridge.length && <div>No results found.</div>}
              {!!seriesBridge && seriesBridge.map((entry) => {
                return (
                  <div className="d-flex border-bottom my-3 pb-2 row App-Flex-Table App-Flex-Table__row" key={`s-${entry.item[0].entryId}`}>
                    <div className="col-3">
                      <img src={entry.item[0].cover || FallbackPoster} alt={`${entry.item[0].title} poster`} className="App-Thumbnail-Poster App-Thumbnail-Poster__smaller" />
                    </div>
                    <div className="col-7">
                      <span className="d-block fs-5">{entry.item[0].title}</span>
                    </div>
                    <div className="col-2"><button className="btn btn-danger" onClick={() => {
                      const updatedList = seriesBridge.filter((entryItem) => entryItem.item[0].entryId !== entry.item[0].entryId);
                      setSeriesBridge(updatedList);
                      handleConnectionDelete(entry.item[0].entryId, 'catalog');
                    }}>Delete</button></div>
                  </div>
                )
              })}
            </section>
          </div>
          <div className="mb-4">
            <div className="bg-light px-2 rounded"><h5 className="lh-lg">Episodes</h5></div>
            <section className="container">
              <div className="d-flex border-bottom my-3 pb-2 row App-Flex-Table">
                <div className="col-3">
                  <span className="fw-semibold fs-5 secondary-font">Thumbnail</span>
                </div>
                <div className="col-7">
                  <span className="fw-semibold fs-5 secondary-font">Title</span>
                </div>
                <div className="col-2">&nbsp;</div>
              </div>
            </section>
            <section className="container">
              {!episodeBridge.length && <div>No results found.</div>}
              {!!episodeBridge && episodeBridge.map((entry) => {
                return (
                  <div className="d-flex border-bottom my-3 pb-2 row App-Flex-Table App-Flex-Table__row" key={`s-${entry.item[0].entryId}`}>
                    <div className="col-3">
                      <img src={entry.item[0].poster || FallbackCover} alt={`${entry.item[0].title} poster`} className="App-Thumbnail-Poster App-Thumbnail-Poster__smaller" />
                    </div>
                    <div className="col-7">
                      <span className="d-block fs-5">{entry.item[0].title}</span>
                      <span className="d-block text-secondary text-small">{entry.parentSeries[0].title}</span>
                    </div>
                    <div className="col-2"><button className="btn btn-danger" onClick={() => {
                      const updatedList = episodeBridge.filter((entryItem) => entryItem.item[0].entryId !== entry.item[0].entryId);
                      setEpisodeBridge(updatedList);
                      handleConnectionDelete(entry.item[0].entryId, 'episodes');
                    }}>Delete</button></div>
                  </div>
                )
              })}
            </section>
          </div>
        </div>
        
        <section className="bg-warning-subtle p-4 mt-5">
          <h4>Danger Zone:</h4>
          <button className="btn btn-danger my-2" onClick={() => setShowConfirmation(true)}>Delete Campaign</button>
          <p className="fs-small">This action can not be undone. Deleting a campaign will also delete associated bridges.</p>
        </section>
      </div>
    </div>
  );
}

export default CampaignDetailPage;