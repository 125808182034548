import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../lib/context/Auth'
import { ErrorBoundary } from 'react-error-boundary'
import FiveHundred from '../components/errors/fiveHundred'

const NoAuthLayout = (props) => {
  const {currentUser} = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if(!!currentUser){
      navigate(`/`);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[currentUser]);

  return (
    <main className='App-Static-Main'>
      <ErrorBoundary fallback={<FiveHundred />}>
        {props.component}
      </ErrorBoundary>
    </main>
  )
}
export default NoAuthLayout;