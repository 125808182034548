import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Dots } from 'react-activity'
import { TagsInput } from 'react-tag-input-component'
import { useFilePicker } from 'use-file-picker'
import {
  FileAmountLimitValidator,
  FileTypeValidator,
  FileSizeValidator,
  ImageDimensionsValidator,
} from 'use-file-picker/validators'
import Toast from './modals/toast'
import { setNewSeries, uploadAsset, setUpdateFields} from '../data/setters'
import ToggleSwitch from './form/toggle'


const SeriesInfoForm = (props) => {
  let defaultSeriesData = {
    active: false,
    cKey: "",
    collaborators: [],
    cover: "",
    description: "",
    featured: false,
    image: "",
    keywords: [],
    sponsor: "",
    subtitle: "",
    title: ""
  };
  const cacheData = props?.defaultData && props?.defaultData[0] ? props?.defaultData[0] : defaultSeriesData;
  const [keywords, setKeywords] = useState(cacheData.keywords);
  const [collaborators, setCollaborators] = useState(cacheData.collaborators);
  const [published, setPublished] = useState(cacheData.active);
  const [isFeatured, setIsFeatured] = useState(cacheData.featured);
  const [seriesName, setSeriesName] = useState(cacheData.title);
  const [seriesSlug, setSeriesSlug] = useState(cacheData.cKey);
  const [seriesDescription, setSeriesDescription] = useState(cacheData.description);
  const [seriesSubTitle, setSeriesSubTitle,] = useState(cacheData.subtitle);
  const [posterImage, setPosterImage] = useState(cacheData.cover);
  const [featuredImage, setFeaturedImage] = useState(cacheData.image);
  const [isLoading, setIsLoading] = useState(false);
  const [activeUpload, setActiveUpload] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [formError, setFormError] = useState(false);
  const navigate = useNavigate();

  const handleChange = () => {
    setShowToast("show");
    setTimeout(() => {
      setShowToast(null);
    },1500);
  }

  const { openFilePicker, filesContent } = useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',
    multiple: false,
    validators: [
      new FileAmountLimitValidator({ max: 1 }),
      new FileTypeValidator(['jpg', 'png']),
      new FileSizeValidator({ maxFileSize: 50 * 1024 * 1024 /* 50 MB */ }),
      new ImageDimensionsValidator({
        maxHeight: 1600, // in pixels
        maxWidth: 2400,
        minHeight: 400,
        minWidth: 720,
      }),
    ],
  });

  const handleFileSelector = (imageType) => {
    setActiveUpload(imageType);
    openFilePicker();
  }

  const handleNewSeries = async () => {
    let addSeries, coverImageUrl = '', featuredImageUrl = '';
    if(!seriesName || !seriesSlug || !seriesDescription) {
      setFormError(true);
      window.scroll(0,0);
      return;
    }

    if(posterImage) {
      coverImageUrl =  await uploadAsset(posterImage, '/posters');
    } 
    if(featuredImage) {
      featuredImageUrl =  await uploadAsset(featuredImage, '/posters');
    }

    const payload = {
      active: published,
      cKey: seriesSlug,
      collaborators: collaborators,
      cover: coverImageUrl,
      description: seriesDescription,
      featured: isFeatured,
      image: featuredImageUrl,
      keywords: keywords,
      subtitle: seriesSubTitle,
      title: seriesName
    }
    console.log('pl', payload);
    if(!!props.defaultData) {
      addSeries = await setUpdateFields(cacheData.entryId, 'catalog', payload);
    } else {
      addSeries = await setNewSeries(payload);
    }

    if(addSeries.code === 500) {
      console.log('There was an error');
    }

    if(!!props.defaultData) {
      // this will only be true if you are in the manage component
      window.scroll(0,0);
      handleChange();
    } else {
      navigate(`/series/${seriesSlug}`);
    }
  }

  useEffect(() => {
    const setTempImage = async () => {
      setIsLoading(true);

      if(activeUpload === "poster") {
        setPosterImage(filesContent[0].content);
      }
      if(activeUpload === "featured") {
        setFeaturedImage(filesContent[0].content);
      }
      setIsLoading(false);
    }

    if(filesContent.length){
      setTempImage();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[filesContent]);

  if(props.defaultData && !cacheData) {
    return;
  }
  return (
    <form id="new-series-form">
     {isLoading && <div className="position-fixed bg-white p-4 rounded top-50 start-50 border">
        <div className="text-cente text-primary">
          <div className="spinner-border" role="status">
          </div>
        </div>
      </div>}
      <Toast title={`Series has been sucessfully ${props.defaultData ? 'updated' : 'added'}.`} trigger={showToast} />
      <section>
        <div className="d-flex flex-row">
          <ToggleSwitch onChange={() => setPublished(!published)} enabled={published} label="Active" classNames="mb-4 me-4"/>
          <ToggleSwitch onChange={() => setIsFeatured(!isFeatured)} enabled={isFeatured} label="Featured" classNames="mb-4"/>
        </div>
        <div>
          <label htmlFor="series-name" className="App-Input-Label mb-2 d-block">Series Name</label>
          <input 
            id="series-name"
            type="text"
            name="series-name"
            value={seriesName}
            placeholder="cool series name"
            className={`form-control App-Input mb-4 ${formError && !seriesName.length ? 'border-danger' : ''}`}
            onInput={(e) => setSeriesName(e.target.value)} onBlur={() => {
              if(!seriesSlug) {
                setSeriesSlug(seriesName.replace(/\s+/g, '-').toLowerCase())
              }
            }} 
            disabled={!!props.defaultData}
          />
        </div>
        <div>
          <label htmlFor="series-slug" className="App-Input-Label mb-2 d-block">Series Slug</label>
          <input
            id="series-slug"
            type="text"
            name="series-slug"
            value={seriesSlug}
            placeholder="cool-series-name"
            className={`form-control App-Input mb-4 ${formError && !seriesSlug.length ? 'border-danger' : ''}`}
            onInput={(e) => setSeriesSlug(e.target.value.replace(/\s+/g, '-').toLowerCase())}
          />
        </div>
        <div>
          <label htmlFor="series-subtitle" className="App-Input-Label mb-2 d-block">Series subtitle</label>
          <input
            id="series-subtitle"
            type="text"
            name="series-name"
            value={seriesSubTitle}
            placeholder="(ex: 3 part mini-series)"
            className="form-control App-Input mb-4"
            onInput={(e) => setSeriesSubTitle(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="series-description" className="App-Input-Label mb-2 d-block">Series Description</label>
          <textarea 
            defaultValue={seriesDescription}
            id="series-description"
            type="text"
            name="series-name"
            className={`form-control App-Input mb-4 ${formError && !seriesDescription.length ? 'border-danger' : ''}`}
            rows={4}
            onInput={(e) => setSeriesDescription(e.target.value)}
          />
        </div>
        <div className="row">
          <div className="col-6">
            <label className="App-Input-Label mb-2 d-block">Series Featured Image <span className="text-small d-block text-secondary">1067x1600</span></label>
            <div className="p-2">
              {!!isLoading && activeUpload === "featured" && <Dots />}
              {(featuredImage)  && <img src={featuredImage} alt="some tag" className="responsive-image uploaded-image-preview" /> }
            </div>
            <button className="btn btn-primary mt-4" type="button" onClick={() => {handleFileSelector("featured")}}>Add Photo</button>
          </div>
          <div className="col-6">
            <label className="App-Input-Label mb-2 d-block">Series Poster Image <span className="text-small d-block text-secondary">768x512</span></label>
            <div className="p-2">
              {!!isLoading && activeUpload === "poster" && <Dots />}
              {(posterImage) && <img src={posterImage} alt="some tag" className="responsive-image uploaded-image-preview" /> }
            </div>
            <button className="btn btn-primary mt-4" type="button" onClick={() => {handleFileSelector("poster")}}>Add Photo</button>
          </div>
        </div>
      </section>
      <section>
        <hr className="line-break" />
        {(collaborators) &&
          <div className="mb-4">
            <span className="App-Input-Label mb-2 d-block">Collaborators</span>
            <TagsInput
              value={collaborators}
              onChange={setCollaborators}
              name="collaborators"
              placeHolder="enter names"
            />
            <em className="text-small fw-lighter">press enter to add new tag</em>
          </div>
        }
        {!!keywords &&
          <div className="mb-4">
            <span className="App-Input-Label mb-2 d-block">Keywords</span>
            <TagsInput
              value={keywords}
              onChange={setKeywords}
              name="keywords"
              placeHolder="enter keywords"
            />
            <em className="text-small fw-lighter">press enter to add new tag</em>
          </div>
        }
      </section>
      <button type="button" className="btn btn-primary" name="submit" onClick={handleNewSeries}>{!!props.defaultData ? 'Update' : 'Create'} Series</button>
    </form>
  );
}

export default SeriesInfoForm;