import { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { getByFieldQuery } from '../../data/getter'
import EpisodeInfoForm from '../../components/episodeInfo'

const NewEpisodePage = () => {
  const [seriesInfo, setSeriesInfo] = useState("");
  let { seriesId } = useParams();

  const fetchSeries = async () => {
    const seriesData = await getByFieldQuery({
      collectionId: "catalog",
      fieldName: "cKey",
      fieldValue: seriesId
    });
    setSeriesInfo(seriesData);
  }

  useEffect(() => {
    fetchSeries();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[seriesId]);

  if(!seriesInfo){
    return;
  }

  return (
    <div className="container">
      <Link to={`/series/${seriesId}`} className="App-Inline-Link App-Inline-Link__plain mb-2 d-inline-block"><i className="bi bi-arrow-left-square"></i> Go back to series</Link>
      <h1 className="text-uppercase fw-bolder App-Page-Title">Create New Episode</h1>
      <EpisodeInfoForm />
    </div>
  );
}

export default NewEpisodePage;