import { useEffect, useState } from 'react';
import { getAllInArray, getCollection } from '../data/getter';
import Loading from './loading';
import { setNewCampaignConnection } from '../data/setters';

const CampaignBridge = (props) => {
  const [seriesCatalog, setSeriesCatalog] = useState([]);
  const [nestedRows, setNestedRows] = useState([{ series: "", videos: [] }]);
  const [showsRows, setShowsRows] = useState([{}]);
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const fetchSeries = async () => {
    const series = await getCollection('catalog');
    setSeriesCatalog(series);
  }

  const handleSeriesDropDown = (selected, indexId, dataAttr) => {
    if (selected === "") {
      nestedRows.splice(indexId, 1);
    } else {
      nestedRows[indexId].series = {
        id: selected,
        sponsor: dataAttr.sponsor,
        title: dataAttr.seriesName
      };
    }
    setNestedRows([...nestedRows]);
    EpisodeSelectDropDown(selected);
  }

  const SeriesSelectDropDown = (props) => {
    return (
      <select
        name="series-select"
        className="form-control App-Input mb-2 w-100"
        onChange={(e) => handleSeriesDropDown(e.target.value, props.indexNum, e.target.options[e.target.selectedIndex].dataset)}
        defaultValue={props.defaultValue}
      >
        <option value={""}>--</option>
        {seriesCatalog.map((item) => {
          const sponsor = item.sponsor.partner || "";
          return <option key={`id-${item.entryId}`} value={item.entryId} data-sponsor={sponsor} data-series-name={item.title}>{item.title}</option>
        })}
      </select>
    )
  }

  const EpisodeSelectDropDown = async (seriesId) => {
    if (seriesId === "") {
      return;
    }
    const selectedSeries = seriesCatalog.filter((item) => item.entryId === seriesId);
    const episdoeData = await getAllInArray("episodes", selectedSeries[0].episodes);
    setShowsRows([...showsRows, ...episdoeData]);
  }

  const handleComplete = async () => {
    const rowsPayload = nestedRows.filter((item) => item.series !== "");
    let noErrors = true;
    setShowError(false);

    if (!rowsPayload.length) {
      props.onSubmit();
      return;
    }
    const payload = {
      partnerId: props.partnerId,
      campaignId: props.campaignId,
      connections: rowsPayload
    }
    rowsPayload.forEach((item) => {
      if (!item.videos.length && item.series.sponsor !== "") {
        // this series already has a sponsor
        noErrors = false;
        setErrorMessage(`${item.series.title} is already sponsored. Please remove it.`);
        setShowError(true);
        return;
      }
    });

    if (noErrors) {
      setIsLoading(true);
      await setNewCampaignConnection(payload);
      setIsLoading(false);
      props.onSubmit();
    }
  }

  useEffect(() => {
    fetchSeries();
  }, []);

  if (!seriesCatalog || isLoading) {
    return <Loading />
  }
  return (
    <div>
      <div className="position-relative pb-4">
        <div className="bg-info-subtle p-2 rounded mb-2"><span className="secondary-font mb-2 fs-small fw-medium">{props.campaignName}</span></div>
        {nestedRows.map((row, index) => {
          const filteredShowList = showsRows?.filter((item) => item.catalogId === nestedRows[index].series.id);
          return (
            <div className="w-100" key={`row-${index}`}>
              <div className="w-100">
                <label className="label-small mb-2">Series:</label>
                <SeriesSelectDropDown defaultValue={nestedRows[index].series.id} indexNum={index} />
              </div>

              {!!nestedRows[index].series && !!showsRows &&
                <div className="nested-row position-relative pb-2">
                  {nestedRows[index].videos && nestedRows[index].videos.map((item, NestedIndex) => {
                    return (
                      <div className="w-100" key={`nested-${NestedIndex}`}>
                        <label className="label-small mb-2">Episode:</label>
                        <div className="d-flex align-items-center">
                          <select
                            name="series-select"
                            className="form-control App-Input mb-2 w-100"
                            onChange={(e) => {
                              nestedRows[index].videos[NestedIndex] = e.target.value;
                              setNestedRows([...nestedRows])
                            }}
                            defaultValue={props.defaultValue}
                          >
                            <option value={""}>--</option>
                            {filteredShowList.map((item) => {
                              return <option key={`id-${item.entryId}`} value={item.entryId}>{item.title}</option>
                            })
                            }
                          </select>
                          <button className="icon-button" onClick={() => {
                            if (nestedRows[index].videos.length <= 1) {
                              nestedRows[index].videos = [];
                            } else {
                              nestedRows[index].videos = nestedRows[index].videos.splice(NestedIndex, 1);
                            }

                            setNestedRows([...nestedRows]);
                          }
                          }><i className="bi bi-dash-circle-fill light-icon"></i></button>
                        </div>
                      </div>
                    )
                  })}
                  <button
                    className="px-2 icon-button d-flex align-items-center"
                    onClick={() => {
                      nestedRows[index].videos = [...nestedRows[index].videos, ["ep-row"]];
                      setNestedRows([...nestedRows])
                    }}><i className="bi bi-plus-lg"></i> <span className="ms-2 text-small">add episode row</span></button>
                </div>
              }
            </div>
          )
        })}
        <button className="px-2 icon-button d-flex align-items-center"
          onClick={() => setNestedRows([...nestedRows, { series: "", videos: [] }])}>
          <i className="bi bi-plus-lg"></i>
          <span className="ms-2 text-small">add series row</span>
        </button>
      </div>
      {showError && <div className="bg-warning-subtle p-2 rounded text-small">{errorMessage}</div>}
      <button className="btn btn-primary my-2" onClick={handleComplete}>All done</button>
    </div>
  )
}

export default CampaignBridge;