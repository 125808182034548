import PartnerInfoForm from '../../components/partnerInfo'

const NewPartnerPage = () => {
  return (
    <div>
      <h1 className="text-uppercase fw-bolder App-Page-Title">Create New Partner</h1>
      <PartnerInfoForm />
    </div>
  );
}

export default NewPartnerPage;