import { useState } from 'react';
import logo from '../plugged-logo--dark.png'
import { PluggedAuth } from '../lib/firebase'
import { setPersistence, signInWithEmailAndPassword, browserSessionPersistence } from 'firebase/auth'

const LoginPage = () => {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [formError, setFormError] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const handleLoginSubmit = () => {
    const domain = email.split("@");
    if((!email || email.length < 6) || (!password || password.length < 5)) {
      setFormError(true);
      setErrorMessage("Email and Password are required.");
      return;
    }
    if(domain[1] !== 'pluggedmediaco.com') {
      setFormError(true);
      setErrorMessage("That email is not authorized for this application.");
      return;
    }
    loginWithEmail();
  }

  const loginWithEmail = async () => {
    setPersistence(PluggedAuth, browserSessionPersistence).then(async () => {
    await signInWithEmailAndPassword(PluggedAuth, email.toLowerCase().trim(), password);
  }).catch((error) => {
    // Handle Errors here.
    const errorMessage = error.message;

    let formErrorMessage;
      switch (errorMessage){
        case "Firebase: Error (auth/wrong-password).":
          formErrorMessage = "Sorry, that password is incorrect. Please try again."
        break;
        case "Firebase: Error (auth/invalid-credential).":
          formErrorMessage = "Sorry, email and password combination faild. Please try again."
        break;
        case "Firebase: Error (auth/user-not-found).":
          formErrorMessage = "Sorry, we could not find an account for that email."
        break;
        default:
          formErrorMessage = errorMessage;
      }
      setErrorMessage(formErrorMessage);
      setFormError(true);
  });
}

  return (
    <section className="App-Login-Wrapper">
      <div className="container h-100">
        <header className="py-4">
          <img src={logo} className="App-logo App-Logo-Login" alt="logo" />
          <span className="px-0 mx-0 mb-4 d-block">Data Manager Tool</span>
        </header>
        <div className="h-50 d-flex align-items-center justify-content-center">
          <div className="w-50">
          {!!formError && <div className="bg-danger p-2 py-3 w-100 mb-4 rounded text-white text-center">{errorMessage}</div>}
            <form id="login-form">
                <div>
                  <label htmlFor="user-email" className="App-Input-Label mb-2 d-block">Email</label>
                  <input 
                    id="user-email" 
                    type="text" 
                    name="user-email" 
                    value={email} 
                    placeholder="yourname@email.com" 
                    className="form-control App-Input mb-4" 
                    onInput={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div>
                  <label htmlFor="user-password" className="App-Input-Label mb-2 d-block">Password</label>
                  <input 
                    id="user-password" 
                    type="password" 
                    name="user-password" 
                    value={password} 
                    placeholder="password" 
                    className="form-control App-Input mb-4" 
                    onInput={(e) => setPassword(e.target.value)}
                  />
                </div>
              <button type="button" className="btn btn-primary" name="submit" onClick={handleLoginSubmit}>Login</button>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LoginPage;