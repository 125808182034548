import React from 'react'
import FourOhFourAnimation from '../../assets/fourOhFourAnimation.gif';

const FourOhFour = () => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <img src={FourOhFourAnimation} alt="404" className="fourOhfour-image" />
      <h4 className="text-uppercase fw-bold">Oh no! Something went wrong</h4>
      <span className="d-block text-secondary">This page could not be loaded. There are a number of reasons this can occur.</span>
      <span className="d-block fs-6 text-body-tertiary">Check your link and try again.</span>
    </div>
  )
}

export default FourOhFour;