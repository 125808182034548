import { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import { getSingleItem } from '../../data/getter'
import PartnerInfoForm from '../../components/partnerInfo'
import NoResultsBanner from '../../components/errors/emptyData'
import CampaignInfoForm from '../../components/campaignInfo'
import CampaignBridge from '../../components/campaignBridge'
import Loading from '../../components/loading'
import FourOhFour from '../../components/errors/fourOhFour'
import { ConvertCampaignObject } from '../../utils/capaignObject'
import Toast from '../../components/modals/toast'

const PartnerDetailPage = () => {
  let { partnerId } = useParams();
  const [partnerData, setPartnerData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [campaignModal, setCampaignModal] = useState(false);
  const [partnerCampaigns, setPartnerCampaigns] = useState([]);
  const [activeCampaignName, setActiveCampaignName] = useState("");
  const [activeCampaign, setActiveCampaign] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showToast, setShowToast] = useState(null);

  const getPartnerInfo = async () => {
    const partnerInfo = await getSingleItem("adPartners", partnerId);

    if(partnerInfo && !partnerInfo[0]) {
      setIsLoading(false);
      return;
    }

    const result = ConvertCampaignObject(partnerInfo[0].campaigns);
    setPartnerData(partnerInfo[0]);
    setPartnerCampaigns(result);
    setIsLoading(false);
  }

  const handleOnNewCampaignSubmit = () => {
    getPartnerInfo();
    setShowModal(false);
  }
  const handleOnNewBridgeSubmit = () => {
    console.log('modal work done');
    setCampaignModal(false);
    setActiveCampaign(null);
    setShowToast(true);
    
    setTimeout(() => {
      getPartnerInfo();
    }, 500);
    
    setTimeout(() => {
      setShowToast(null);
    },1500);
  }
  const handleConnection = (campaignId, campName) => {
    setCampaignModal(true);
    setActiveCampaign(campaignId);
    setActiveCampaignName(campName);
    getPartnerInfo();
  }

  useEffect(() => {
    getPartnerInfo();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  if(!!isLoading) {
    return <Loading />
  }

  if(!isLoading && !partnerData) {
    return <FourOhFour />
  }

  return (
    <div>
      <Toast title="New bridge connection created." trigger={showToast} />
      {showModal && <div className="modal-wrapper">
        <div className="modal d-flex align-items-center" tabIndex="-1">
          <div className="modal-dialog w-50">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Create Campaign</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowModal(false)}></button>
              </div>
              <div className="modal-body">
                <CampaignInfoForm partnerId={partnerId} onSubmit={handleOnNewCampaignSubmit} />
              </div>
            </div>
          </div>
        </div>
      </div> }
      {campaignModal && <div className="modal-wrapper">
        <div className="modal d-flex align-items-center" tabIndex="-1">
          <div className="modal-dialog w-100">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Connection Bridge</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setCampaignModal(false)}></button>
              </div>
              <div className="modal-body">
                <CampaignBridge partnerId={partnerId} campaignId={activeCampaign} campaignName={activeCampaignName} onSubmit={handleOnNewBridgeSubmit} />
              </div>
            </div>
          </div>
        </div>
      </div> }
      <Link to={`/partners/`} className="App-Inline-Link App-Inline-Link__plain mb-2 d-inline-block"><i className="bi bi-arrow-left-square"></i> Go back to partners list</Link>
      <h1 className="text-uppercase fw-bolder App-Page-Title">Manage Partner</h1>
      <PartnerInfoForm defaultData={partnerData} />
      <hr />
      <div>
        <section className="mt-4">
          <div className="d-flex justify-content-between pb-4 mb-4">
            <h4>Campaigns <i className="bi bi-diagram-2"></i></h4>
            <button className="btn btn-outline-dark" name="new-campaign" onClick={() => setShowModal(true)}>Add New Campaign</button>
          </div>
        </section>
        <section className="container">
          {partnerCampaigns && !!partnerCampaigns.length ?
            <div>
              <div className="d-flex border-bottom my-3 pb-2 row App-Flex-Table">
                <div className="col-6">
                  <span className="fw-semibold fs-5 secondary-font">Campaign</span>
                </div>
                <div className="col-1 text-center">
                  <span className="fw-semibold fs-5 secondary-font">Bridges</span>
                </div>
                <div className="col-5">&nbsp;</div>
              </div>
              {partnerCampaigns.map((campaign) => {
                return(
                  <div className="row border-bottom mb-2 pb-2 mx-0 App-Flex-Table App-Flex-Table__row" key={`${campaign.entryId}`}>
                    <div className="col-6 ps-0">
                    {campaign.name}
                    </div>
                    <div className="col-1 text-center">
                      <span className="fs-6 text-secondary">{campaign.eligible?.length}</span>
                    </div>
                    <div className="col-5 d-flex justify-content-end">
                      <Link to={`/partners/${partnerId}/${campaign.entryId}`} className="btn btn-dark me-2 fs-small">Edit Campaign</Link>
                      <button className="btn btn-outline-dark fs-small" onClick={() => handleConnection(campaign.entryId, campaign.name)}>Add Connection</button>
                    </div>
                  </div>
                )
              })
            } </div> :  <NoResultsBanner name="campaigns" /> }
        </section>
      </div>
    </div>
  );
}

export default PartnerDetailPage;